import React from "react";
import Layout from "../components/Layout";

import "../styles/privacy.less";

const PrivacyPage: React.FC = (props) => {
  return (
    <Layout>
      <main>Privacy</main>
    </Layout>
  );
};

export default PrivacyPage;
